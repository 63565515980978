/**
 * external libs
 */
import React, { useCallback, useEffect, useState, useContext } from 'react'
import Slider from 'react-slick'
/**
 * components
 */
import TopUpAccountPhis from './phis/TopUpAccountPhis'
import TopUpAccountEntity from './entity/TopUpAccountEntity'
import TopUpAccountDriver from './driver/TopUpAccountDriver'
import Portal from './../../common-components/portal/Portal'
/**
 * context
 */
import { GlobalContext } from './../../App'
/**
 * types
 */
import { RolesType, GlobalContextType, CardType, WalletType } from './../../types'
/**
 * styles
 */
import styles from './../layouts.module.scss'

type Props = {
    card: Partial<WalletType> & CardType
    setPortalData: React.Dispatch<React.SetStateAction<any>>
}

const MenuCard: React.FC<Props> = ({ card, setPortalData }) => {
    const { user } = useContext<GlobalContextType>(GlobalContext)

    return (
        <div
            key={card.id}
            {...(user?.Role === RolesType.drivers
                ? {
                      onClick: () => {
                          user?.Role !== RolesType.drivers && setPortalData(card.id)
                      },
                      className: `${styles.cart} ${styles.cart_click}`,
                  }
                : { className: styles.cart })}>
            <p className={styles.cart__number}>{card.cardNumber}</p>

            {(user?.Role === RolesType.companies || user?.Role === RolesType.individuals) && (
                <>
                    <div className={styles.cart__rest}>
                        <p className={styles.cart__label}>Остаток, {card.sizePurse} ₽</p>
                        <p className={styles.cart__count}>{card.idCondition} л</p>
                    </div>

                    <button className={styles.cart__button} onClick={() => setPortalData(card.id)} type="button">
                        Выставить счет
                    </button>
                </>
            )}
        </div>
    )
}

export default MenuCard
