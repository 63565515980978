/**
 * external libs
 */
import React, { useContext, useState, useRef } from 'react'
/**
 * components
 */
import Button from './../../../../common-components/button/Button'
import Field from './../../../../common-components/field-public-form/Field'
import Input from './../../../../common-components/input-public-form/Input'
/**
 * utils
 */
import useSender from './../../../../utils/sender'
/**
 * context
 */
import { GlobalContext } from './../../../../App'
/**
 * types
 */
import { CardType, GlobalContextType } from './../../../../types'
/**
 * styles
 */
import styles from './../../../layouts.module.scss'
import DropDown from '../../../../common-components/drop-down/DropDown'

type Props = {
    card: CardType
    setEditCard: React.Dispatch<React.SetStateAction<any | null>>
}

const EditCard: React.FC<Props> = ({ card, setEditCard }) => {
    const { user, addAlert } = useContext<GlobalContextType>(GlobalContext)
    const { http } = useSender()

    const [selectedTypeLimit, setSelectedTypeLimit] = useState(
        card.monthlyLimit && card.monthlyLimit > 0 ? 'Месяц' : 'Сутки'
    )
    const [limit, setLimit] = useState(selectedTypeLimit === 'Месяц' ? card?.monthlyLimit : card?.dailyLimit)
    const block = useRef(false)

    const limitHandler = async () => {
        if (!block.current && !!user?.uID && !!card.id) {
            block.current = true

            const limitSum = limit ? Number(limit) : 0

            try {
                await http.post(`/companies/${user.uID}/wallet/${card.id}/limit`, {
                    limitType: selectedTypeLimit === 'Сутки' ? 1 : 3,
                    limitSum: limitSum,
                })

                setEditCard(null)
            } catch (e: any) {
                if (addAlert) {
                    addAlert({ text: `Ошибка запроса: ${e?.code} | ${e?.status} | ${e?.config?.url}`, type: 'error' })
                }
            }

            block.current = false
        }
    }

    const typeLimitOptions = [
        {
            id: 'Сутки',
            label: 'Сутки',
        },
        {
            id: 'Месяц',
            label: 'Месяц',
        },
    ]
    return (
        <div className={styles.drivers__edit}>
            <div className={styles.drivers__cardsListScroll}>
                <div className={styles.drivers__fieldListWrapper}>
                    {/* <div className={styles.drivers__fieldTypeTag}><span>A-92</span></div> */}

                    <Field label="Тип лимита">
                        <DropDown
                            values={
                                selectedTypeLimit
                                    ? [typeLimitOptions.find((option) => option.id === selectedTypeLimit) as any]
                                    : []
                            }
                            changeHandler={(vals) => {
                                setSelectedTypeLimit(vals[0].id)
                                setLimit(vals[0].id === 'Месяц' ? card?.monthlyLimit : card?.dailyLimit)
                            }}
                            placeholder=""
                            isGray={true}
                            options={typeLimitOptions}
                        />
                    </Field>

                    <Field label="Объем" error={''}>
                        <Input
                            placeholder="л"
                            isError={false}
                            value={limit}
                            change={(e: React.ChangeEvent<HTMLInputElement>) => {
                                const floatData = parseFloat(e.target.value)
                                if (!isNaN(floatData)) {
                                    setLimit(e.target.value.trim())
                                }
                            }}
                        />
                    </Field>
                </div>
            </div>

            <div className={styles.drivers__buttonsBlock}>
                <Button label="Сохранить" type="button" subClasses={styles.drivers__button} handler={limitHandler} />

                <Button
                    label="Удалить лимит"
                    type="button"
                    subClasses={`${styles.drivers__button} ${styles.drivers__button_red}`}
                    handler={() => {
                        setLimit('')
                        limitHandler()
                    }}
                />
            </div>
        </div>
    )
}

export default EditCard
