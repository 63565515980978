/**
 * external libs
*/
import React, { useState, useRef, useCallback } from "react";
import { Range } from 'react-date-range';
import { Transition } from "react-transition-group";
/**
 * components
*/
import DatepickerRangeCalendar from "./DatepickerRangeCalendar";
import Button from "./../button/Button";
/**
 * styles
*/
import styles from './datepicker-range.module.scss';


type Props = {
    ico: React.ReactElement;
    handleSelect: (rangesByKey: Range[]) => void;
    dpRange: Range[];
    label: string;
};

const DatepickerRange: React.FC<Props> = ({ ico, label, handleSelect, dpRange }) => {
    const [open, setOpen] = useState<boolean>(false);
    const [dpRangeLocal, setDPRangeLocal] = useState<Range[]>([...dpRange]);
    const dpWrapperRef = useRef(null);

    const icoModern = React.cloneElement(ico, {
        className: styles.calendar__ico,
    });

    const cancelHandler = useCallback(() => {
        setOpen(false);
        setDPRangeLocal([...dpRange]);
    }, [setOpen, setDPRangeLocal, dpRange]);

    const saveHandler = useCallback(() => {
        setOpen(false);
        handleSelect(dpRangeLocal);
    }, [setOpen, dpRangeLocal, handleSelect]);

    return (
        <div className={styles.calendar__wrapper}>
            <button
                type="button"
                className={styles.calendar__button}
                onClick={() => setOpen(true)}
            >
                {icoModern}
                <span className={styles.calendar__label}>{label}</span>
            </button>

            <Transition nodeRef={dpWrapperRef} in={open} timeout={250} unmountOnExit={false}>
                {(state: string) =>
                    <div
                        ref={dpWrapperRef}
                        className={`${styles.calendar__form} ${(state === "entering" || state === "entered" || state === "exiting") ? styles.calendar__form_vis : ""} ${state === "entered" ? styles.calendar__form_show : ""}`}
                    >
                        <DatepickerRangeCalendar
                            handleSelect={item => setDPRangeLocal([item.selection])}
                            dpRange={dpRangeLocal}
                        />

                        <div className={styles.calendar__footer}>
                            <Button
                                label="Отмена"
                                handler={cancelHandler}
                                buttonStyle="outline"
                            />

                            <Button
                                label="Принять"
                                handler={saveHandler}
                            />
                        </div>
                    </div>
                }
            </Transition>
        </div>
    )
};

export default DatepickerRange;