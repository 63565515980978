/**
 * external libs
 */
import React from "react";
/**
 * components
*/
import Button from "../../../common-components/button/Button";
import Field from "../../../common-components/field/Field";
/**
 * styles
*/
import styles from './../../layouts.module.scss';

const TopUpAccountPhis: React.FC<{ close: Function }> = ({ close }) => {
    return (
        <div className={styles.form} >
            <div className={styles.form__container}>
                <p className={styles.form__date}>27.03.2022 в 3:03</p>

                <div className={styles.form__finishInfo}>
                    <Field
                        label="Топливная карта №"
                        noError={true}
                    >
                        <div className={styles.form__cardBG}>
                            <p className={styles.form__cardNumber}>1111-0000-1111-0000</p>
                        </div>
                    </Field>

                    <Field
                        label="Пользователь карты"
                        noError={true}
                    >
                        <p className={styles.form__userFIO}>Николай Константинов DRIVER</p>
                    </Field>

                    <Field
                        label="Сумма"
                        noError={true}
                    >
                        <p className={`${styles.form__userFIO} ${styles.form__userFIO_green}`}>+ 5 000 Руб.</p>
                    </Field>
                </div>
            </div>

            <Button
                label="Скачать"
                type="button"
                subClasses={styles.form__submit}
                handler={close}
            />
        </div>
    );
};

export default TopUpAccountPhis;
